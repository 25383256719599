import React, { useState } from "react";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import Testimonial from "../Testimonial";
import Div from "../Div";
import Spacing from "../Spacing";
export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const testimonialData = [
    {
      testimonialThumb:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/sameer-testomonial.png?updatedAt=1708844814056",
      testimonialText:
        "Our journey is fueled by the love for sports and a commitment to delivering quality products and services. We appreciate your role in making our dream a reality. Together, let's continue to push boundaries, embrace challenges, and celebrate victories.",
      avatarName: "Mohammed Shameer Khader",
      avatarDesignation: "Founder, Managing Director",
      ratings: "5",
    },
    {
      testimonialThumb:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/shareef-testomonial.png?updatedAt=1708844814045",
      testimonialText:
        "As we stride forward on this exhilarating journey, I want to express my heartfelt gratitude for being a part of our sports community. Your passion for sports fuels our dedication to excellence.",
      avatarName: "Mohammed Sharief",
      avatarDesignation: "Managing Director",
      ratings: "5",
    },
    // {
    //   testimonialThumb:
    //     "https://ik.imagekit.io/nce63ejyh/OlympicSports/sameer-testomonial.png?updatedAt=1708844814056",
    //   testimonialText:
    //     "Our journey is fueled by the love for sports and a commitment to delivering quality products and services. We appreciate your role in making our dream a reality. Together, let's continue to push boundaries, embrace challenges, and celebrate victories.",
    //   avatarName: "Mohammed Shameer Khader",
    //   avatarDesignation: "Founder, Managing Director",
    //   ratings: "5",
    // },
    {
      testimonialThumb:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/nizam-testomonial.png?updatedAt=1708844814017",
      testimonialText:
        "Your dedication to sports and our brand drives us to continually deliver top-notch products and services. Together, let's forge ahead, meeting challenges with resilience, and celebrating every triumph along the way",
      avatarName: "Nizamuddin Abdul Khader T",
      avatarDesignation: "Managing Director",
      ratings: "5",
    },
  ];
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
