import React from "react";
import Portfolio from "../Portfolio";
import Div from "../Div";
import Slider from "react-slick";

export default function PortfolioSlider() {
  const portfolioData = [
    {
      title: "Women's tight ",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/Shunya_studios-116.webp?updatedAt=1708547484204",
    },
    {
      title: "Item Eight",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/Shunya_studios-32.webp?updatedAt=1708547484112",
    },
    {
      title: "Zipper Jacket",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/B7C9F630-CAE0-41D9-80DC-4E9B8DCE7A1F.JPEG.webp?updatedAt=1708547484222",
    },
    {
      title: "Gym duffle bag",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/WhatsApp-Image-2023-10-21-at-11.19.42_b3150518.jpg?updatedAt=1708547484019",
    },
  ];

  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-slider cs-style3 cs-gap-24">
      {portfolioData.map((item, index) => (
        <Div key={index}>
          <Portfolio
            title={item.title}
            subtitle={item.subtitle}
            href={item.href}
            src={item.src}
          />
        </Div>
      ))}
    </Slider>
  );
}
