import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";

export default function ContactPage() {
  // const handleButtonClick = () => {
  //   window.location.href = "mailto:store@olympicsportsmlr.com";
  // };

  // const handleButtonClick = () => {
  //   // Fetch form values
  //   const fullName = document.getElementById("fullName").value;
  //   const email = document.getElementById("email").value;
  //   const projectType = document.getElementById("projectType").value;
  //   const mobile = document.getElementById("mobile").value;
  //   const note = document.getElementById("note").value;

  //   // Construct email body
  //   const emailBody = `Full Name: ${fullName}%0D%0AEmail: ${email}%0D%0AProject Type: ${projectType}%0D%0AMobile: ${mobile}%0D%0ANote: ${note}`;

  //   // Open default email client with pre-filled email
  //   window.location.href = `mailto:store@olympicsportsmlr.com?subject=Form Submission&body=${encodeURIComponent(
  //     emailBody
  //   )}`;
  // };

  const handleButtonClick = () => {
    // Fetch form values
    const fullName = document.getElementById("fullName").value;
    const email = document.getElementById("email").value;
    const projectType = document.getElementById("projectType").value;
    const mobile = document.getElementById("mobile").value;
    const note = document.getElementById("note").value;

    // Construct email body with line breaks
    const emailBody = `Full Name: ${fullName}\nEmail: ${email}\nProject Type: ${projectType}\nMobile: ${mobile}\nNote: ${note}`;

    // Open default email client with pre-filled email
    window.location.href = `mailto:store@olympicsportsmlr.com?subject=Enquiries&body=${encodeURIComponent(
      emailBody
    )}`;
  };

  pageTitle("Contact Us");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.jpeg"
        pageLinkText="Contact"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Do you have a game plan <br/>in your mind?"
              subtitle="Getting Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form action="#" className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name*</label>
                <input type="text" className="cs-form_field" id="fullName" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email*</label>
                <input type="text" className="cs-form_field" id="email" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type*</label>
                <input type="text" className="cs-form_field" id="projectType" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile*</label>
                <input type="text" className="cs-form_field" id="mobile" />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Note*</label>
                <textarea
                  cols="30"
                  rows="7"
                  className="cs-form_field"
                  id="note"
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button
                  className="cs-btn cs-style1"
                  onClick={handleButtonClick}
                >
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3916.524800224318!2d74.8371848!3d12.8630076!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba35bad11554c29%3A0xe495f45e288bb547!2sState%20Bank%20Bus%20Stand!5e0!3m2!1sen!2sin!4v1645731000000!5m2!1sen!2sin"
          allowFullScreen
          title="Google Map"
        />
      </Div>
      <Spacing lg="50" md="40" />
    </>
  );
}
