import { Icon } from "@iconify/react";
import React from "react";
import Slider from "react-slick";
import Div from "../Div";
import Team from "../Team";

export default function TeamSlider() {
  /** Team Member Data **/
  const teamData = [
    {
      memberImage:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/sameer.png?updatedAt=1708843657747",
      memberName: "Mohammed Shameer Khader",
      memberDesignation: "Founder, Managing Director",
      memberSocial: {
        // linkedin: "/",
        twitter: "/",
        youtube: "/",
        facebook: "/",
      },
    },
    {
      memberImage:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/shareef.png?updatedAt=1708843657672",
      memberName: "Mohammed Sharief",
      memberDesignation: "Managing Director",
      memberSocial: {
        // linkedin: "/",
        twitter: "/",
        youtube: "/",
        facebook: "/",
      },
    },
    {
      memberImage:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/nizam.png?updatedAt=1708843657665",
      memberName: "Nizamuddin Abdul Khader T",
      memberDesignation: "Managing Director",
      memberSocial: {
        // linkedin: "/",
        twitter: "/",
        youtube: "/",
        facebook: "/",
      },
    },
    // {
    //   memberImage: "/images/member_4.jpeg",
    //   memberName: "Melina Opole",
    //   memberDesignation: "WP Developer",
    //   memberSocial: {
    //     linkedin: "/",
    //     twitter: "/",
    //     youtube: "/",
    //     facebook: "/",
    //   },
    // },
    // {
    //   memberImage: "/images/member_1.jpeg",
    //   memberName: "Melon Bulgery",
    //   memberDesignation: "Product Designer",
    //   memberSocial: {
    //     linkedin: "/",
    //     twitter: "/",
    //     youtube: "/",
    //     facebook: "/",
    //   },
    // },
  ];
  /** Slider Settings **/
  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24 cs-arrow_style2">
      {teamData.map((item, index) => (
        <Div key={index}>
          <Team
            memberImage={item.memberImage}
            memberName={item.memberName}
            memberDesignation={item.memberDesignation}
            memberSocial={item.memberSocial}
          />
        </Div>
      ))}
    </Slider>
  );
}
