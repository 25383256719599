import React from "react";
import Slider from "react-slick";
import Div from "../Div";
import Post from "../Post";

export default function PostSlider() {
  const postData = [
    {
      url: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/c.webp?updatedAt=1708549363115",
      alt: "Post",
      date: "21 Feb 2024",
      title: "Exploring the Pinnacle of Sports Fashion and Gear",
    },
    {
      url: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/training.webp?updatedAt=1708546996365",
      alt: "Post",
      date: "21 Feb 2024",
      title: "Exploring the Pinnacle of Sports Fashion and Gear",
    },
    {
      url: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/g.webp?updatedAt=1708549364744",
      alt: "Post",
      date: "21 Feb 2024",
      title: "Exploring the Pinnacle of Sports Fashion and Gear",
    },
    {
      url: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/e.jpg?updatedAt=1708549371303",
      alt: "Post",
      date: "21 Feb 2024",
      title: "Exploring the Pinnacle of Sports Fashion and Gear",
    },
    {
      url: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/Football.webp?updatedAt=1708546996230",
      alt: "Post",
      date: "21 Feb 2024",
      title: "Exploring the Pinnacle of Sports Fashion and Gear",
    },
    {
      url: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/running.webp?updatedAt=1708546817374",
      alt: "Post",
      date: "21 Feb 2024",
      title: "Exploring the Pinnacle of Sports Fashion and Gear",
    },
  ];

  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="cs-gap-24">
      {postData.map((item, index) => (
        <Div key={index}>
          <Post
            url={item.url}
            src={item.src}
            alt={item.alt}
            date={item.date}
            title={item.title}
          />
        </Div>
      ))}
    </Slider>
  );
}
