import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Portfolio from "../Portfolio";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";

export default function PortfolioPage() {
  pageTitle("Portfolio");
  const [active, setActive] = useState("all");
  const [itemShow, setItemShow] = useState(7);
  const portfolioData = [
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/e.jpg?updatedAt=1708549370655",
      category: "ui_ux_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/a.webp?updatedAt=1708549367645",
      category: "logo_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/Shunya_studios-116.webp?updatedAt=1708547484204",
      category: "web_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/Shunya_studios-32.webp?updatedAt=1708547484112",
      category: "mobile_apps",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/WhatsApp-Image-2023-10-21-at-11.19.42_b3150518.jpg?updatedAt=1708547484019",
      category: "ui_ux_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/g.webp?updatedAt=1708549364744",
      category: "web_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/B7C9F630-CAE0-41D9-80DC-4E9B8DCE7A1F.JPEG.webp?updatedAt=1708547484222",
      category: "logo_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/Football.webp?updatedAt=1708546996230",
      category: "ui_ux_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/training.webp?updatedAt=1708546996365",
      category: "logo_design",
    },
    {
      title: "Colorful Art Work",
      subtitle: "See Details",
      href: "/portfolio",
      src: "https://ik.imagekit.io/nce63ejyh/OlympicSports/running.webp?updatedAt=1708546817374",
      category: "web_design",
    },
  ];
  const categoryMenu = [
    {
      title: "Apparel",
      category: "web_design",
    },
    {
      title: "Bags",
      category: "ui_ux_design",
    },
    {
      title: "Sport",
      category: "mobile_apps",
    },
    {
      title: "Customization",
      category: "logo_design",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Gallery"
        bgSrc="images/portfolio_hero_bg.jpeg"
        pageLinkText="Gallery"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Portfolio" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === "all" ? "active" : ""}>
                <span onClick={() => setActive("all")}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? "active" : ""}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioData.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Cta
        title="store@olympicsportsmlr.com"
        bgSrc="/images/cta_bg_2.jpeg"
        variant="rounded-0"
      />
    </>
  );
}
