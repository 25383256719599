import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Hero7 from "../Hero/Hero7";

export default function CaseStudyShowcaseHome() {
  pageTitle("Case Study Showcase");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const heroSocialLinks = [
    {
      name: "Instagram",
      links: "/",
    },
    {
      name: "Facebook",
      links: "/",
    },
  ];

  const showcaseData = [
    {
      title: "Score big with our <br />latest arrivals",
      imgUrl:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/Shunya_studios-116.webp?updatedAt=1708547484204",
      href: "/",
    },
    {
      title: "Score big with our  <br />latest arrivals",
      imgUrl:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/WhatsApp-Image-2023-10-21-at-11.19.42_b3150518.jpg?updatedAt=1708547484019",
      href: "/",
    },
    {
      title: "Score big with our <br />latest arrivals",
      imgUrl:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/Shunya_studios-32.webp?updatedAt=1708547484112",
      href: "/",
    },
    {
      title: "Score big with our <br />latest arrivals",
      imgUrl:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/b.webp?updatedAt=1708549366488",
      href: "/",
    },
    {
      title: "Score big with our  <br />latest arrivals",
      imgUrl:
        "https://ik.imagekit.io/nce63ejyh/OlympicSports/e.jpg?updatedAt=1708549371303",
      href: "/",
    },
  ];
  return (
    <>
      <Hero7
        heroSocialLinks={heroSocialLinks}
        socialLinksHeading="Follow Us"
        showcaseData={showcaseData}
      />
    </>
  );
}
